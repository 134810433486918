import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StoreService } from '@services/store/store.service';
import { ApiService } from '@services/api/api.service';
import { closePanels } from '../../app/modules/client/utils';
import { defaultDarkTheme, defaultLightTheme, defaultLogo, defaultQuickPages } from '../../app/modules/client/default-ui-setting-values';
import { CMSUserType } from '../../app/modules/client/constant';
import { ICustomer, IUser, defaultUISettings } from '@app/models/user';
import revision from '@app/app.revision';
import { LayoutService, ColorScheme } from 'src/app/layout/service/app.layout.service';
import { MenuItem, MessageService } from 'primeng/api';
import { map } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent {

  menu: MenuItem[] = [];

  @ViewChild('searchinput') searchInput!: ElementRef;

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild('profileMenu') profileMenu!: ElementRef;
  searchActive: boolean = false;

  // Basic User Settings
  title: any;                // title of project
  userId: number;            // user id
  // colorScheme = 'light';      // theme mode (dark, light)
  user: IUser;               // user information
  quickPages = [];           // quick page links
  avatar = '';               // avatar image data(base64)
  logoImg: string = defaultLogo;          // logo image data(base64)
  versionNumber = revision;
  currentRoute = '';
  isShowTemplateFilter: boolean = false;
  isSuperAdmin = false;

  isSorted = false;
  balance = 0;

  placeholder = 'loading';

  cmsUserType = CMSUserType;
  menuItems!: MenuItem[];

  userInfo = {
    name: '',
    avatarName: '',
    email: '',
    role: 'Administrator',
    accountName: '',
    accountID: '',
    supportPIN: '',
    accountingType: 0,
  };

  selectedCustomer: any = {};
  customerList: ICustomer[] = [];

  isCustomerPanel: boolean = false;
  impersonateToken: any;

  selectedTemplate: any;
  currentTemplate: any;
  customerId: any;
  templates: any[] = [];
  constructor(
    private route: Router,
    private routerdata: ActivatedRoute,
    public store: StoreService,
    private api: ApiService,
    private messageService: MessageService,
    public layoutService: LayoutService,
    private renderer: Renderer2
  ) {
    this.title = route.url;

    // debugger
    this.title = this.title.replace(/\//g, '');
    this.title = this.title.toUpperCase();
    // this.defaultUiSettings = layoutService.getDeafultUISettings();
  }

  async ngOnInit() {
    this.currentRoute = this.route.url;
    this.isShowTemplateFilter = this.currentRoute.includes('activity') ? true : false;
   
    if(this.isShowTemplateFilter){
      this.clearSelectedTemplate();
    }

    this.route.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.currentRoute = this.route.url;
        this.isCustomerPanel = this.currentRoute.includes('customer-panel') ? true : false;
        this.isShowTemplateFilter = this.currentRoute.includes('activity') ? true : false;

        if(this.isShowTemplateFilter){
          this.clearSelectedTemplate();
        }

        if (this.isCustomerPanel && !this.customerList.length) {
          this.api.getAllCustomerList().pipe(
            map((data: any) => {
              data.unshift({ id: '', companyName: 'Select a Customer' });
              return data
            })
          ).subscribe(customers => this.customerList = customers);
        }
      }
    });

    await new Promise<void>(resolve => {
      const mainUserInterval = setInterval(() => {
        if (this.store.getUser() && this.store.getGuiVisibility()) {
          clearInterval(mainUserInterval);
          resolve();
        }
      }, 100);
    });

    this.routerdata.data.subscribe(d => {
      this.title = d.title;
      this.title = this.title.replace(/\//g, '');
      this.title = this.title.toUpperCase();
    });

    // set theme mode and logo image
    this.store.state$.subscribe(async state => {
      if (state.token) {
        this.userId = state.token.userId;
      }
      this.isSuperAdmin = state.userPermissions?.isSuperAdmin;
      if (state.token && state.user && state.guiVisibility) {
        this.user = state.user;

        this.userInfo.name = this.user.firstName + ' ' + this.user.lastName;
        if (this.user.firstName.length > 0) this.userInfo.avatarName = this.user.firstName.substr(0, 1);
        if (this.user.lastName.length > 0) this.userInfo.avatarName += this.user.lastName.substr(0, 1);
        this.userInfo.email = this.user.email;

        if (this.user.hasOwnProperty('Customer')) {
          this.userInfo.accountName = this.user.Customer.companyName;
          this.userInfo.accountID = this.user.Customer.billingId == null ? '' : this.user.Customer.billingId;
          this.userInfo.accountingType = this.user.Customer.accounting_type;
        }

        if (
          this.user.hasOwnProperty('DashRoleMapping') &&
          Array.isArray(this.user.DashRoleMapping) &&
          this.user.DashRoleMapping.length > 0 &&
          this.user.DashRoleMapping[0].hasOwnProperty('DashRole')
        )
          this.userInfo.role = this.user.DashRoleMapping[0].DashRole.name;

        let uiSettings = JSON.parse(state.user.uiSettings);

        // Update Balance if any
        if (this.user.hasOwnProperty('Customer') && this.user.Customer.hasOwnProperty('balance'))
          this.balance = this.user.Customer['balance'];
        else this.balance = 0;

        // Default UI Settings if none
        if (!uiSettings) {
          uiSettings = defaultUISettings;

          this.colorScheme = uiSettings.colorScheme;
          this.applyTheme(this.colorScheme);
          this.user.uiSettings = JSON.stringify(uiSettings);
          this.store.storeUser(this.user);
          try {
            this.api.updateUser(this.user).subscribe(res => {
              console.log("User updated.");
            });
          } catch (e) {
            console.log("Unable to update user.");
          }
        }

        // if the ui setting information of the user exists
        if (uiSettings) {
          // set custom logo image
          if (uiSettings.customLogoImg != undefined) this.logoImg = uiSettings.customLogoImg;

          this.quickPages = JSON.parse(JSON.stringify(defaultQuickPages)); // quick page links

          // set quick page links
          if (uiSettings.customQuickPageEnables != undefined) {
            for (let i = 0; i < uiSettings.customQuickPageEnables.length; i++) {
              if (this.quickPages[i] != undefined) this.quickPages[i].isQuick = uiSettings.customQuickPageEnables[i];
            }
          }

          // check quickPages that belongs to the user's role permission
          // if (this.store.getUserType() != CMSUserType.superAdmin) {
          //   for (let i = this.quickPages.length - 1; i >= 0; i--) {
          //     let permission = PERMISSION_TYPE_DENY;
          //     if (this.user.DashRoleMapping && this.user.DashRoleMapping.length > 0) {
          //       for (const v of state.guiVisibility) {
          //         if (GUI_VISIBILITY_MATCH[v.GuiSection.name] == this.quickPages[i].title) {
          //           permission = v.GuiPermission.name;
          //           break;
          //         }
          //       }
          //     }

          //     if (permission == PERMISSION_TYPE_DENY) this.quickPages[i].isQuick = false;
          //   }
          // }

          const quickPages = JSON.parse(JSON.stringify(this.quickPages));
          await quickPages.sort((page1, page2) => page1.isQuick - page2.isQuick);
          this.quickPages = quickPages.filter(page => page.isQuick);

          // set custom avatar
          if (uiSettings.customAvatar != undefined) this.avatar = uiSettings.customAvatar;

          if (uiSettings.colorScheme) this.colorScheme = uiSettings.colorScheme;
        }
        this.setMenuItems();
      }
    });

    // subscribe for balance update
    this.store.getBalance().subscribe((value: any) => {
      if (value) this.balance = value;
    });

      this.store.getImpersonateToken().subscribe((token: any) => {
          this.impersonateToken = token;
      });
      this.getReportTemplates();
      this.customerId = this.store.getUser()?.customerId;
      this.store.loadCurrentTemplate$.subscribe((template) => {
        this.currentTemplate = template;
        this.getReportTemplates();
      });
  }

  get colorScheme(): ColorScheme {
    return this.layoutService.config().colorScheme;
  }
  set colorScheme(_val: ColorScheme) {
    if (_val == 'dark') {
      this.layoutService.config.update((config) => ({
        ...config,
        layoutTheme: 'colorScheme',
      }));
    }
    this.layoutService.config.update((config) => ({
      ...config,
      colorScheme: _val,
    }));
  }

  closeMenu() {
    this.renderer.addClass(this.profileMenu.nativeElement, 'hidden');
  }

  setActiveQuickButton(el) {
    if (el?.classList.contains('active-btn')) {
      let color = window.getComputedStyle(el)?.getPropertyValue('color');
      color = color.replace(')', ', 0.5)');
      return color;
    }
  }

  setMenuItems() {
    const { isSuperAdmin } = this.store.getUserPermissions();
    const MENU_ITEMS: any = [{
      label: this.userInfo.accountName,
      items: [
        {
          label: `<div class="custom-menu-list-item">
                  <p>ACCOUNT ID: <span>${this.userInfo.accountID}</span></p>
                  <p class="hidden">SUPPORT PIN: <span>${this.userInfo.supportPIN}</span></p>
                  <p>BALANCE: <span>$${this.balance}</span></p>
                  </div>`,
          styleClass: 'no-hover',
          escape: false,
          icon: 'pi pi-fw pi-user-edit',
          routerLink: '/service/user/account',
          visible: isSuperAdmin ? false : true,
        },
        {
          separator: true,
          visible: isSuperAdmin ? false : true,
        },

        {
          label: 'User Profile',
          icon: 'pi pi-fw pi-user-edit',
          routerLink: '/service/user/edit/' + this.userId,
          escape: true,
        },
        // {
        //   label: 'Account Settings', icon: 'pi pi-fw pi-cog', routerLink: '/service/user/edit/' + this.userId
        // },
        {
          label: 'Billing Settings',
          icon: 'pi pi-fw pi-wallet',
          routerLink: '/service/billing/settings',
          visible: isSuperAdmin ? false : true,
        },
        {
          label: this.colorScheme == 'dark' ? 'Light Mode' : 'Dark Mode',
          icon: 'pi pi-fw pi-send',
          command: event => {
            this.toggleMode();
          },
        },
        {
          separator: true,
        },
        {
          label: 'Sign Out',
          icon: 'pi pi-fw pi-sign-out',
          command: event => {
            this.onSignout();
          },
        },
      ]
    }]
    this.menuItems = MENU_ITEMS;
  }

  toggleMode() {
    this.colorScheme = this.colorScheme == 'dark' ? 'light' : 'dark';
    this.setMenuItems();
    JSON.parse(this.store.getUser().uiSettings);

    const user = this.store.getUser();
    if (user) {
      let uiSettings = JSON.parse(user.uiSettings);
      uiSettings.colorScheme = this.colorScheme;
      user.uiSettings = JSON.stringify(uiSettings)
      this.api.updateUser(user).subscribe(
        res => {
          if (res) {
            this.showSuccess('UI settings successfully updated.');
          } else {
            this.showWarning('Wrong input supplied, please check data and try again.');
          }
        },
        error => {
          console.log("error", error)
        });
    }
  }

  applyTheme(theme) {
    // return this.layoutService.config().colorScheme;

    // this.layoutService.applyTheme(theme.key, theme.mode, theme.pace);
  }



  get isAdmin() {
    //return false;
    return this.user && this.user.id == 1;
  }

  showWarning = (msg: string) => {
    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Warning', detail: msg });
  };

  /**
   * this is called when the user clicks sign out button
   */
  onSignout = () => {
    this.api.logout().subscribe(res => {
      this.store.clearStoreAndLogout();
    }, err => {
      this.store.clearStoreAndLogout();
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: err.error.message });
    });
  };

  /**
   * this is called when the user clicks the quick page icon
   */
  onClickQuickPage = () => {
    closePanels();
  };

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  activateSearch() {
    this.searchActive = true;
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
  }

  deactivateSearch() {
    this.searchActive = false;
  }

  removeTab(event: MouseEvent, item: MenuItem, index: number) {
    event.preventDefault();
  }

  get logo(): string {
    return 'assets/images/cms-logo2.png';
  }

  get tabs(): MenuItem[] {
    return [];
  }

  redirectToLogin() {
    this.route.navigate(['/login'])
  }

  deimpersonate() {
    const impersonateToken = JSON.parse(localStorage.getItem('impersonateToken'));
    this.store.removeImpersonateToken();
    this.api.deimpersonate(impersonateToken.id).subscribe(() => {
    const token = this.store.retrieveToken();
    this.api.retrieveLoggedUserOb(token).subscribe(res => {
       this.impersonateToken = null;
        this.showSuccess('Deimpersonate User successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    }, error => {
      this.showError('Failed to  deimpersonate:');
    });
  }

  getReportTemplates() {
    const filter = {
      where: {
        principalId: this.customerId
      }
    }
    this.api.getReportTemplates(filter).subscribe((templates: any[]) => {
      this.templates = templates;
      this.selectedTemplate =  this.templates.find(x=> x.id == this.currentTemplate?.id );
    });
  }

  onApplyTemplate() {
    this.store.setTemplate(this.selectedTemplate);
  }

  openTemplateDeleteConfirmation(templateId: number) {
    this.store.deleteSelectedTemplate(templateId);
  }

  clearSelectedTemplate() {
    this.selectedTemplate = null;
    this.store.setTemplate(null);
  }

  showSuccess = (msg: string) => {
    this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: msg });
  };

  showError = (msg: string) => {
    this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: msg });
  };
}
